import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clearButton', 'input'];

  connect() {
    this.showOrHideClearButton();
  }

  showOrHideClearButton() {
    const clearButton = this.clearButtonTarget;
    const inputElement = this.inputTarget;

    if (inputElement.value) {
      clearButton.style.display = 'block';
    } else {
      clearButton.removeAttribute('style');
    }
  }

  clearInputValue() {
    this.inputTarget.value = '';
    this.clearButtonTarget.removeAttribute('style');
  }
}
