// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  'Visit the guide for more information: ',
  'https://vite-ruby.netlify.app/guide/rails'
);

// Example: Load Rails libraries in Vite.

import * as Turbo from '@hotwired/turbo';
Turbo.start();

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

import 'trix';
import '@rails/actiontext';

// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { Application } from 'stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

const application = Application.start();
const controllers = import.meta.globEager('../controllers/**/*_controller.js');
registerControllers(application, controllers);

import SmartListing from 'smart_listing';
application.register('smart-listing', SmartListing.controllers.main.tailwind);
application.register('smart-listing-controls', SmartListing.controllers.controls.tailwind);
