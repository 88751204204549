import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sliderMin', 'sliderMax'];

  minGap = 0;
  minValue = this.sliderMinTarget.min;
  maxValue = this.sliderMinTarget.max;

  connect() {
    this.handleSliderMin();
    this.handleSliderMax();
  }

  handleSliderMin() {
    const sliderMaxValue = parseInt(this.sliderMaxTarget.value, 10);
    const sliderMinValue = parseInt(this.sliderMinTarget.value, 10);
    const maxValue = parseInt(this.maxValue, 10);

    if (sliderMaxValue - sliderMinValue <= this.minGap) {
      this.sliderMinTarget.value = sliderMaxValue - this.minGap;
    }

    if (sliderMaxValue === maxValue && sliderMinValue === maxValue) {
      this.sliderMaxTarget.style.setProperty('--thumbDisplay', 'none');
    } else {
      this.sliderMaxTarget.style.setProperty('--thumbDisplay', 'block');
    }
  }

  handleSliderMax() {
    const sliderMaxValue = parseInt(this.sliderMaxTarget.value, 10);
    const sliderMinValue = parseInt(this.sliderMinTarget.value, 10);

    if (sliderMaxValue - sliderMinValue <= this.minGap) {
      this.sliderMaxTarget.value = sliderMinValue + this.minGap;
    }
  }
}
