import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hiddenSection'];

  toggleHiddenSection(e) {
    const hiddenSection = this.hiddenSectionTarget;

    ['hidden', 'pointer-events-none', '!mt-0'].map((cssClass) =>
      hiddenSection.classList.toggle(cssClass)
    );

    if (hiddenSection.classList.contains('hidden')) {
      e.target.textContent = 'Read more';
    } else {
      e.target.textContent = 'Hide';
    }
  }
}
