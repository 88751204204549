import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'leftSpace'];

  connect() {
    const currentPath = document.location.pathname;
    const filtersElement = document.getElementById('filters');

    this.linkTargets.forEach((link) => {
      link.classList.remove('active');

      const linkPath = link.dataset.page;

      if (currentPath.includes(linkPath)) {
        link.classList.add('active');
      }

      if (filtersElement) {
        this.leftSpaceTarget.classList.add('lg:block', 'lg:flex-1');
      } else {
        this.leftSpaceTarget.classList.add('hidden');
      }
    });
  }
}
