import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['charsCountPlaceholder', 'charsCountPlaceholderTrix'];

  countChars(e) {
    this.charsCountPlaceholderTargets.forEach((charsCountPlaceholder) => {
      if (charsCountPlaceholder.dataset.textarea === e.target.id) {
        charsCountPlaceholder.textContent = e.target.value.length;
      }
    });
  }

  countTrixChars(e) {
    const { editor, dataset } = e.target;
    const string = editor.getDocument().toString();
    const charCount = string.length - 1;
    this.charsCountPlaceholderTrixTarget.textContent = charCount;

    if (charCount > dataset.maxLength) {
      const newString = string.substring(0, dataset.maxLength);
      editor.setSelectedRange([0, dataset.maxLength]);
      editor.insertString(newString);
    }
  }
}
