import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['innerSticky'];

  BREAKPOINT = 1024;

  connect() {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const header = document.querySelector('header');
    const headerHeight = header.clientHeight;

    const availableHeight = viewportHeight - headerHeight;
    const innerStickyInitialHeight = this.innerStickyTarget.clientHeight;

    if (
      viewportWidth >= this.BREAKPOINT &&
      innerStickyInitialHeight > availableHeight
    ) {
      this.innerStickyTarget.style.height = `${availableHeight}px`;
    }
  }
}
